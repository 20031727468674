define(['app', '$window'], (app, window) => {
  const purchaseScript = () => {
    const component = {};

    const _updateBasket= () => {
      component.update(window.dataLayer[0].basketProducts);
    }

    const _update = (dataItems) => {
      let ShoeSizeShoppingCart = {"shopID": "14617462","purchases": []};
      window.ShoeSizeShoppingCart = ShoeSizeShoppingCart;

      dataItems.forEach(function (arrayItem) {
        let item = {
          "shoeID": arrayItem.childMasterProductId || arrayItem.masterId,
          "size": arrayItem.size,
          "scale": "uk", // downscale
          "quantity": arrayItem.quantity,
          "price": arrayItem.price
        }
        ShoeSizeShoppingCart.purchases.push(item);
      });
      component.runScript();
    }

    const postData = async () => {
      const response = await fetch("https://shoesize.me/api/config/integrity",
        {method: 'GET'})

      return response.json();
    }

    // Loop throught the items in the cart
    // and push their properties into the purchases object

    // Do not modify
    const _runScript = () => {

      postData().then((integrity) => {
        (function () {
          var script = document.createElement('script');
          script.type = 'text/javascript';
          script.crossOrigin = 'anonymous';
          script.integrity = integrity.cart_integrity;
          script.src = 'https://shoesize.me/plugin/cart.js?' +
            'shopid=' + encodeURIComponent(ShoeSizeShoppingCart.shopID) +
            '&sid=' + Math.round(new Date().getTime() / 1000);
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
        })();
      });
    }

    component.updateBasket = _updateBasket;
    component.update = _update;
    component.runScript = _runScript;

    return component;
  }
  return purchaseScript;
});
